import { apiCredentials } from './http';

export const sendLogs = async ({ device, companySettings, errors, settings }) => {
  const arrConnectLink = settings?.connectLink.split('/');
  const dto = {
    displayType: device?.type || '',
    time: new Date().toISOString(),
    errorMessage: errors ? errors : '',
    spaceId: '',
    spaceTitle: '',
    companyId: arrConnectLink ? arrConnectLink[arrConnectLink.length - 1] : '',
    companyName: companySettings?.companyName || '',
    accessToken: localStorage.getItem(apiCredentials.accessTokenKey),
  };
  try {
    await fetch('https://savelogs-rauegybb6a-uc.a.run.app/inspaceapp-test/us-central1/saveLogs', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(dto),
    });
  } catch (err) {
    console.error(err);
  }
};

export const sendActionLogs = async ({ device, companySettings, errors, settings, actionName, actionPath }) => {
  const arrConnectLink = settings?.connectLink.split('/');
  const dto = {
    displayType: device?.type || '',
    time: new Date().toISOString(),
    errorMessage: errors ? errors : '',
    spaceId: '',
    spaceTitle: '',
    companyId: arrConnectLink ? arrConnectLink[arrConnectLink.length - 1] : '',
    companyName: companySettings?.companyName || '',
    accessToken: localStorage.getItem(apiCredentials.accessTokenKey),
    userActionName: actionName || '',
    userActionPath: actionPath || '',
  };
  try {
    await fetch('https://savelogs-rauegybb6a-uc.a.run.app/inspaceapp-test/us-central1/saveActionLogs', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(dto),
    });
  } catch (err) {
    console.error(err);
  }
};
