import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { replayIntegration, BrowserTracing, init } from '@sentry/react';
import { BrowserRouter, createRoutesFromChildren, useLocation } from 'react-router-dom';
import { DeviceAuthContextProvider } from '@inspace-org/react-auth';
import { apiCredentials, apiUrl } from './services/http';
import { language } from './localization';
import { ExtraErrorData } from '@sentry/integrations';

init({
  dsn: 'https://98dcc07017b5522cfc15d86e51260404@o4505722220118016.ingest.sentry.io/4506428324970496',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['https://test-reception.inspace.app/', 'https://reception.inspace.app/'],
    }),
    new ExtraErrorData({ depth: 10 }),
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DeviceAuthContextProvider apiUrl={apiUrl} credentials={apiCredentials} language={language}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </DeviceAuthContextProvider>,
);
