import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../common/CardLayout';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowRight } from '../../icons';
import './DeliveryPage.scss';
import SearchUsers from '../common/SearchUsers';
import ThanksCard from '../common/ThanksCard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NotificationClient } from '../../services/http/apis/notificationClient';
import { UsersClient } from '../../services/http/apis/usersClient';
import { captureException } from '@sentry/react';
import { sendActionLogs } from '../../services/logs';
import { DeviceAuthContext } from '@inspace-org/react-auth';

const validationSchema = yup.object({
  user: yup.string(),
});

const initialValues = {
  user: '',
};

const DeliveryPage = () => {
  const { settings, device, companySettings } = useContext(DeviceAuthContext);
  const notificationClient = new NotificationClient();
  const { t } = useTranslation();
  const usersClient = new UsersClient();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [valueUser, setValueUser] = useState(null);
  const [transitionClass, setTransitionClass] = useState('right-to-left');

  const nextCard = (type) => {
    setTransitionClass('right-to-left');
    if (step === 0 && type === 'name') {
      getUsers();
      setStep(1);
    }
    if (step === 0 && type === 'phone') {
      setStep(2);
    }
    if (step === 1 || step === 2) {
      formik.submitForm();
      setStep(3);
    }
  };

  const handelBackButton = () => {
    setTransitionClass('left-to-right');
    if (step === 0) {
      navigate('/welcome');
    }
    if (step === 1) {
      setValueUser(null);
    }
    if (step === 2) {
      setStep(0);
      setValueUser(null);
    } else {
      setStep(step - 1);
    }
  };

  const getUsers = async () => {
    try {
      await usersClient
        .getUsers()
        .then(async (res) => await usersClient.getUsers({ limit: res.pagination.totalCount }))
        .then((res) => {
          setUsers(res.users);
        });
    } catch (err) {
      captureException(err);
      console.error(err);
      sendActionLogs({
        errors: err,
        device,
        companySettings,
        settings,
        actionName: 'get Users Delivery',
        actionPath: 'Delivery component',
      });
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      try {
        await notificationClient.sendNotificationDelivery(valueUser?.email);
      } catch (err) {
        captureException(err);
        console.error(err);
        sendActionLogs({
          errors: err,
          device,
          companySettings,
          settings,
          actionName: 'send Notification Delivery',
          actionPath: 'Delivery component',
        });
      }
    },
  });

  const hasError = (field) => Boolean(formik.touched[field] && formik.errors[field]);
  const helperTextFor = (field, defaultText) => (hasError(field) ? formik.errors[field] : defaultText);

  return (
    <TransitionGroup style={{ height: '100%' }}>
      <CSSTransition key={step} classNames={transitionClass} timeout={1000}>
        <div className="form">
          {step === 0 && (
            <CardLayout title="delivery.title" onBack={handelBackButton}>
              <div className="delivery">
                <div className="delivery_text">
                  <Trans i18nKey="delivery.whom_notify" />
                </div>
                <div className="delivery_buttons">
                  <button onClick={() => nextCard('name')} className="delivery_item">
                    <Trans i18nKey="delivery.search_name" />
                    <ArrowRight />
                  </button>
                  <button onClick={() => nextCard('phone')} className="delivery_item">
                    <Trans i18nKey="delivery.search_number" />
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </CardLayout>
          )}
          {step === 1 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="delivery.note_title"
                buttonText={'submit_btn'}
                onBack={handelBackButton}
                onClick={nextCard}
                disabled={formik.values.user === '' || hasError('user')}
              >
                <div className="delivery_note">
                  <Trans i18nKey="delivery.note_label" />
                </div>
                <SearchUsers
                  typeSearch="name"
                  value={valueUser}
                  helperText={helperTextFor('user')}
                  onChange={(value) => formik.setFieldValue('user', value)}
                  onBlur={formik.handleBlur}
                  error={hasError('user')}
                  users={users}
                  setValueUser={setValueUser}
                  placeholder={t('delivery.note_placeholder')}
                />
              </CardLayout>
            </CSSTransition>
          )}
          {step === 2 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="delivery.note_title"
                buttonText={'submit_btn'}
                onBack={handelBackButton}
                onClick={nextCard}
                disabled={formik.values.user === '' || hasError('user')}
              >
                <div className="delivery_note">
                  <Trans i18nKey="delivery.note_phone_label" />
                </div>
                <SearchUsers
                  typeSearch="phone"
                  value={valueUser}
                  helperText={helperTextFor('user')}
                  onChange={(value) => formik.setFieldValue('user', value)}
                  onBlur={formik.handleBlur}
                  error={hasError('user')}
                  users={users}
                  setValueUser={setValueUser}
                  placeholder={t('delivery.note_phone_placeholder')}
                />
              </CardLayout>
            </CSSTransition>
          )}
          {step === 3 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <ThanksCard user={valueUser} text="delivery.thanks_text" onClick={() => navigate('/welcome')} />
            </CSSTransition>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DeliveryPage;
