import React, { useContext, useEffect, useRef, useState } from 'react';
import './SearchUsers.scss';
import Spinner from './Spinner';
import { UsersClient } from '../../services/http/apis/usersClient';
import { debounce, getUserDisplayName } from '../../services/utils';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { captureException } from '@sentry/react';
import { sendActionLogs } from '../../services/logs';

const SearchUsers = ({ value = '', onBlur, onChange, setValueUser, helperText, error, placeholder, hostsList }) => {
  const { settings, device, companySettings } = useContext(DeviceAuthContext);
  const [userList, setUsersList] = useState(hostsList || []);
  const [isLoadingUser, setLoadingUser] = useState(false);
  const usersListWrapRef = useRef(null);
  const usersClient = new UsersClient();

  useEffect(() => {
    if (usersListWrapRef.current && userList.length > 2) {
      usersListWrapRef.current.classList.add('form_shadow');
      usersListWrapRef.current.addEventListener('scroll', (e) => {
        if (
          usersListWrapRef.current.scrollHeight - usersListWrapRef.current.scrollTop ===
          usersListWrapRef.current.clientHeight
        ) {
          usersListWrapRef.current.classList.remove('form_shadow');
        } else {
          usersListWrapRef.current.classList.add('form_shadow');
        }
      });
    }
  }, [usersListWrapRef, userList]);

  const loadUsers = async (value) => {
    if (value !== '') {
      try {
        setLoadingUser(true);
        const { users } = await usersClient.getUsers({ search: value });
        setUsersList(users);
      } catch (err) {
        setUsersList([]);
        captureException(err);
        console.error(err);
        sendActionLogs({
          errors: err,
          device,
          companySettings,
          settings,
          actionName: 'load Hosts',
          actionPath: 'Search Host component',
        });
      } finally {
        setLoadingUser(false);
      }
    } else {
      setUsersList([]);
    }
  };

  const handelChangeInput = (e) => {
    const val = e.target.value;
    getDebouncedUsers(val);
    setValueUser(val);
    onChange('');
  };

  const getDebouncedUsers = debounce(loadUsers, 100);

  const handleSelectUser = (user) => {
    setValueUser(getUserDisplayName(user));
    onChange(user._id || user.id);
  };

  return (
    <div className="form_field">
      <input
        className={`form_input ${error ? 'error' : ''}`}
        name="hosts"
        placeholder={placeholder}
        value={value}
        onChange={handelChangeInput}
        onBlur={onBlur}
      />
      {error && <div className="error_text">{helperText}</div>}
      {isLoadingUser ? (
        <Spinner />
      ) : (
        <div className="form_users" ref={usersListWrapRef}>
          {userList.map((user) => (
            <div key={user._id || user.id} className="user" onClick={() => handleSelectUser(user)}>
              <div className="user_avatar">
                <div className="avatar">{getUserDisplayName(user, 'avatar').toUpperCase()}</div>
              </div>
              <div className="user_name">{getUserDisplayName(user)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchUsers;
